.ui.grid > .row > .Movie {
  padding: 0 6px;
}

.Movie > figure {
  margin: 0 0 12px 0;
  background-color: #DDD;
  background-size: cover;
  color: #FFF;
  transition: all 250ms;
  cursor: pointer;
}

.Movie > figure > .figurecaption {
  position: absolute;
  left: 6px;
  right: 6px;
  top: 50%;
  bottom: 12px;
  background-image: linear-gradient(to top, rgba(0,0,0,0.95), rgba(0,0,0,0));
  overflow: hidden;
  transition: all 500ms;
}

.Movie > figure > .figurecaption > p {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 14px;
  text-align: center;
  transition: all 250ms;
}

.Movie > figure > .figurecaption > .icon {
  position: absolute;
  bottom: -8px;
  width: 100%;
  transition: all 250ms;
}

.Movie > figure:hover > .figurecaption {
  top: 40%;
}

.Movie > figure:hover > .figurecaption > p {
  bottom: 24px;
}

.Movie > figure:hover > .figurecaption > .icon {
  bottom: 20px;
}