.TheaterProgramming .poster {
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
  width: 100px;
  height: 135px;
}

.TheaterProgramming h2 {
  color: #333;
  margin-top: 16px;
  padding: 12px 10px;
  font-size: 15px;
  line-height: 13px;
  background-color: #F0F0F0;
  border-radius: 4px;
}

.TheaterProgramming h3 {
  color: #444;
  margin-top: 16px;
  padding: 6px 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 12px;
  background-color: #EEE;
  border-radius: 4px;
}

.TheaterProgramming h4 {
  color: #555;
  font-size: 12px;
  line-height: 15px;
  margin-top: 16px;
}

.TheaterProgramming h5 {
  color: #555;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}

.TheaterProgramming p {
  color: #555;
  font-size: 11px;
  line-height: 8px;
  margin-bottom: 8px;
}

.TheaterProgramming > div {
  margin-left: 10px;
}

.TheaterProgramming > div > div {
  margin: 16px 0 0 16px;
}

.TheaterProgramming > div > div > div {
  text-align: center;
  display: inline-grid;
  margin-left: 16px;
}

.sorting-row {
  display: table !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.sorting-row td {
  padding: 11px;
}
