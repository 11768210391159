.Player .playlist {
  background-color: #F6F6F6;
}

.playlist-item {
  font-size: 12px;
  position: relative;
  background-color: #FFF;
  user-select: none;
  padding: 8px 10px;
  border-bottom: 1px solid #EFEFEF;
}

.playlist-item p {
  margin: 0 0 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playlist-item .ui.label, .Player .ui.label {
  font-size: 9px;
  padding: 4px;
}

.playlist-item .ui.label.blue {
  background-color: #5f8fa1!important;
}

.playlist-item .ui.label.orange {
  background-color: #eb8b4b!important;
}

.playlist-item .ui.label.red {
  background-color: #c75959!important;
}

.Player .list {
  margin-top: 0;
}

.Player .list .item {
  user-select: none;
  cursor: pointer;
  padding: 8px 18px!important;
}

.Player .list .item .header {
  font-weight: 600;
  font-size: 11px;
}

.Player .item:hover {
  background-color: #FAFAFA;
}

.Player .search {
  margin-left: 0;
}
