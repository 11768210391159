.ContentHeader {
  background-color: #F6F6F6;
}

.ContentHeader .section {
  font-size: 11px;
  text-transform: uppercase;
}

.ContentHeader .ui.breadcrumb .divider {
  margin: 0px 6px;
}

.ui.grid > .ContentHeader > .column {
  padding: 4px 20px;
}

.ui.grid > .ContentHeader > .column.advertisement {
  padding: 8px 20px;
}

.ContentHeader .header {
  margin-top: 2px;
}

.ContentHeader .column .button {
  min-height: 44px;
  height: 100%;
  border-radius: 30px;
}