.FileManager .list .item .content .description .label {
  padding: 4px;
  margin: 2px 4px 0 0;
}

.FileManager .list .item .content .description .label.blue {
  background-color: #5f8fa1!important;
}

.FileManager .list .item .content .description .label.orange {
  background-color: #eb8b4b!important;
}

.FileManager .list .item .content .description .label.red {
  background-color: #c75959!important;
}