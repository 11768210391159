.App-Order .segment.info .ui.header {
  font-size: 14px;
}

.App-Order .segment.info .ui.header .sub {
  font-size: 11px;
  margin-top: 2px;
}

.App-Order .segment.info .ui.header .sub:first-child {
  margin-top: 8px;
}

.App-Order .segment.stat {
  padding: 12px;
}

.App-Order .segment.stat .ui.header {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}

.App-Order .segment.stat .ui.header .sub {
  font-weight: 700;
  font-size: 13px;
  margin-top: 4px;
}

.App-Order table {
  text-align: center;
}

.App-Order table tr, .App-Order table th {
  text-align: center;
}

.App-Order table .button {
  padding: 6px;
  font-size: 9px;
}