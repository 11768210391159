.Order [class^="icone-"] {
  display: block;
  margin: 10px 0 0;
  font-size: 32px;
}

.Order > .TitledBlock > .column > div > .ui.button {
  width: 180px;
  line-height: 21px;
  margin: 8px;
}

.search {
  display: inline-block;
  margin-left: 10px;
  width: 35%;
}

.search > .results {
  max-height: 280px;
  overflow-y: auto;
}

.search > .input {
  width: 100%;
}