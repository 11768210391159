.ProductOptions {
  display: flex;
}

.ProductOptions .left-panel {
  width: 35%;
  padding: 40px 10px 40px 40px;
}

.ProductOptions .left-panel .header {
  margin-bottom: 18px;
}

.ProductOptions a {
  display: block;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.ProductOptions .smaller .ui.statistic > .value {
  font-size: 18px!important;
}

.ProductOptions span .ui.checkbox {
  font-size: 11px;
  line-height: 12px;
}

.ProductOptions span .ui.checkbox > label {
  padding-left: 16px;
}

.ProductOptions span .ui.checkbox > label::before {
  width: 12px;
  height: 12px;
}

.ProductOptions span .ui.checkbox > label::after {
  width: 11px;
  font-size: 9px;
  line-height: 13px;
}