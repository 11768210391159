.FinancialAdmin table {
  text-align: center;
}

.FinancialAdmin table tr, .App-Order table th {
  text-align: center;
}

.FinancialAdmin table .button {
  padding: 6px;
  font-size: 9px;
}