.Login {
  height: 100%;
}

.Login > .column {
  max-width: 450px;
}

.Login .ui.form > p.copyright {
  margin-top: 32px;
  color: #444;
  font-size: 10px;
}

.Login div.forgot-password {
  margin: -7px 0 14px 0;
  text-align: left;
}

.Login div.forgot-password > a {
  cursor: pointer;
  font-size: 13px;
}