body {
  margin: 0;
  padding: 0;
}

body > div.root {
  height: 100%;
}

.content-margin {
  margin: 5px;
}

.green {
  color: #26C720;
}

.ui.left.input > .DayPickerInput > input {
  padding-left: 2.67142857em!important;
  padding-right: 1em!important;
}

.ui.form > .fields > .field {
  width: 100%;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput > input {
  width: 100%;
}

.DayPickerInput > div {
  line-height: initial;
}

/* SIGNATURE PAD */
.m-signature-pad {
  margin: 18px 0;
  position: relative;
  font-size: 10px;
  height: 300px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #FFF;
}

.DayPickerInput input {
  width: 100%!important;
}

.Reports .legend-item-0 {
  cursor: pointer;
}

.filepicker.dropzone {
  background-color: #F6F6F6;
}

.dz-default.dz-message {
  color: #006A92;
}

.dz-default.dz-message h1 {
  font-size: 15px;
  margin: 0;
  font-weight: 600;
}

.dz-default.dz-message p {
  font-size: 14px;
  margin: 4px 0 0 0;
}

.dz-preview.dz-image-preview {
  background-color: transparent;
}

.UserEditor .ui.dropdown .menu {
  max-height: 290px;
}

.DayPicker-wrapper, .DayPicker-Day {
  outline: none;
}