.TitledBlock > .column > .ui.header {
  padding: 10px 16px;
  background-color: #EEE;
  text-transform: uppercase;
  font-size: 15px;
}

.TitledBlock > div > .ui.segment {
  box-shadow: none;
  border-color: #EEE;
  padding: 20px;
}