.App-TopBar {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 66px;
  top: 0;
  background-color: #E5E5E5;
}

.App-TopBar .image {
  float: left;
  width: 112px;
  margin: 6px 58px 0 12px;
}

.App-TopBar .icone-barras {
  position: relative;
  top: 18px;
  left: 17px;
  cursor: pointer;
  font-size: 30px;
}

.App-TopBar > .reload {
  float: right;
  color: #FFF;
  background-color: #589945;
  margin: 16px 6px 0 0;
  padding: 6px 16px;
  box-shadow: 0 0 6px rgba(0,0,0,0.4);
  border-radius: 4px;
  cursor: pointer;
}

.App-TopBar > .ui.dropdown {
  float: right;
}

.App-TopBar > .ui.dropdown > .menu.transition {
  width: 100%;
  border-radius: 0;
}

.App-TopBar .UserMenu {
  display: flex;
}

.App-TopBar .UserMenu:hover {
  cursor: pointer;
  background-color: #CCC;
}

.ui.menu.App-Menu {
  margin-top: 66px;
  overflow-y: auto;
  height: auto;
  width: 230px!important;
  bottom: 0;
  background: linear-gradient(to bottom,rgba(51,122,183,0.1),rgba(0,0,0,0.8)), black url(/imgs/menu.png)  -310px -276px;
}

.App-Menu [class^="icone-"], .App-Menu [class*=" icone-"] {
  margin: 0 .5em 0 0!important;
}

.App-Menu .active.item  {
  background-color: rgba(51,122,183,0.85)!important;
}

.App-Menu .active.item:hover  {
  background-color: rgba(51,122,183,0.85)!important;
}

.App-Menu .item:hover {
  background-color: rgba(51,122,183,0.45)!important;
}

.App-Content {
  position: relative;
  top: 66px;
  margin: 0px 0 0 230px;
  transition: margin 300ms ease-out;
}