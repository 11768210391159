.ui.grid > .row > .MovieViwer {
  margin: 0 6px 12px 6px;
  padding: 20px;
  color: #FFF;
  min-height: 260px;
  background-color: #000;
  overflow: hidden;
}

.MovieViwer > span {
  display: block;
}

.MovieViwer > .backdrop {
  display: inline;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.MovieViwer > .backdrop:before {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  background: linear-gradient(to right, #000, transparent);
}

.MovieViwer > div {
  z-index: 2;
  position: relative;
  display: flex;
  text-shadow: 0 0 2px #000;
}

.MovieViwer > .title {
  font-size: 16px;
  font-weight: 700;
}

.MovieViwer > .subtitle {
  font-size: 12px;
  font-weight: 500;
}

.MovieViwer > .info, .MovieViwer > .trailers {
  margin: 16px 0 24px;
  font-size: 12px;
}

.MovieViwer > .trailers {
  font-size: 14px;
  font-weight: 600;
}

.MovieViwer > .info > ul, .MovieViwer > .trailers > ul  {
  margin: 0;
  padding: 0;
  list-style: none;
}

.MovieViwer > .info > ul > li, .MovieViwer > .trailers > ul > li {
  float: left;
  margin-right: 30px;
}

.MovieViwer > .synopsis {
  font-size: 13px;
  width: 60%;
}